import React from 'react';
import {StyledBackground} from "./styledBackground";
import BG from '../../munich.jpg';

function Background() {

    return (
        <StyledBackground>
            <img src={BG} alt={'munich'}/>
        </StyledBackground>
    )
}

export default Background;