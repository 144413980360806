import styled from 'styled-components';
import { down} from 'styled-breakpoints';

const StyledFooter = styled.div`
      background: rgba(255,255,255,0.6);
  color: black;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  width: 100%;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
      a {
            text-decoration: none;
            color: black;
      }

  .container {
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-column-gap: 2em;
      padding: 1em;
   
      ${down('md')} {
         white-space: nowrap; /* [1] */
         overflow-x: auto; /* [2] */
         -webkit-overflow-scrolling: touch; /* [3] */
         -ms-overflow-style: -ms-autohiding-scrollbar;
      } 
  }
`;

export { StyledFooter };