import React from 'react';
import Background from "../../components/background/background";

function Home() {

    return (
        <>
            <Background/>
        </>
    )
}

export default Home;