import React from 'react';
import {StyledMainLayout} from "./styledMainLayout";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";

function MainLayout({ components }) {

    return (
        <StyledMainLayout>
            <Header/>
            { components.map((Component, i) => {
                return <Component key={i}/>
            })}
            <Footer/>
        </StyledMainLayout>
    )
}

export default MainLayout;