import React from "react";
import {Route, Routes} from 'react-router-dom';
import MainLayout from "./layout/mainLayout";
import Home from "./content/home/home";

function AppRoutes() {

    return (
        <Routes>
            <Route path={'/'} element={
                <MainLayout components={[Home]} />
            }/>
        </Routes>
    );
}

export default AppRoutes;