import React from 'react';
import {StyledFooter} from "./styledFooter";
import {Link} from "react-router-dom";
import dayjs from "dayjs";

function Footer() {

    return (
        <StyledFooter>
            <div className={'container'}>
                <h5>© { dayjs().format('YYYY')} | it-scholz.com</h5>
                <Link to={'/imprint'}><h5>Impressum</h5></Link>
            </div>
        </StyledFooter>
    )
}

export default Footer;