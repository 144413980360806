import React from 'react';
import {StyledHeader, StyledLogo} from "./styledHeader";
import Logo from '../../scholz-logo.svg';

function Header() {

    return (
        <StyledHeader>
            <div className={'container'}>
                <StyledLogo>
                    <img src={Logo} alt="It-Scholz Logo" />
                </StyledLogo>
            </div>
        </StyledHeader>
    )
}

export default Header;